.pageTitle {
  border: 2px solid #f2f2f2;
  text-align: center;
}
.pageTitle h2 {
  margin: 0;
  padding: 1rem 0;
  font-weight: 400;
  font-size: 1.6rem;
}
