body {
  background-color: #fff !important;
  /* overflow: overlay; */
}
a {
  color: #ad1911;
  text-decoration: underline;
}
.Main-app {
  overflow: hidden;
}
/* ::-webkit-scrollbar { */
/* width: 0px;
  height: 0px; */
/* } */

/* Track */
/* ::-webkit-scrollbar-track { */
/* background: transparent; */
/* } */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c51416;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c51416;
}

html,
body {
  height: 100%;

  padding: 0;

  margin: 0;
}

._container {
  max-width: 1200px;
  margin: 0 auto;
  border-right: 2px solid #f2f2f2;
  border-left: 2px solid #f2f2f2;
  /* border-bottom: 2px solid #f2f2f2; */
  position: relative;
}
.MuiList-root {
  width: 100%;
}
._container-noborder {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
._container-three-borders {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  border-right: 2px solid #f2f2f2;
  border-left: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  border-top: 2px solid #f2f2f2;
}
.default__container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.anderetype {
  transform: scaleX(1) !important;
}
