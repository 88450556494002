.MuiTableCell-body {
  padding: 0.25rem 2rem !important;
}
.MuiTableCell-head {
  padding: 0.25rem 1.9rem !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #02b05a !important;
}

.screenSwitchAnimaiton {
  animation-name: screenSwitchAnimaiton;
  animation-duration: 0.5s;
}
@keyframes screenSwitchAnimaiton {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
