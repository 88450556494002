.termsContainer {
  max-width: 1200px !important;
  margin: 50px auto !important;
}
.typographyTerms {
  padding: 30px 0;
  font-size: 1.5rem;
}
@media only screen and (max-width: 500px) {
  .termsContainer h4 {
    font-size: 1.5rem;
  }
  .typographyTerms {
    font-size: 0.8rem !important;
  }
}
